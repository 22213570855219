import React, { useEffect, useState } from "react";
import { Col, Row, Input, FormGroup, Form, Label, Button } from "reactstrap";
import { BsDownload } from "react-icons/bs";
import Card from 'react-bootstrap/Card';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const UserDirector = () => {
    let count = useLocation();
    // console.log("Count", count)
    var numcount = count.state?.count;

    let navigate = useNavigate();
    let [formData, setFormData] = useState({
        //first director
        fullnamefirst: '',
        emailidfirst: '',
        mobilenumberfirst: '',
        addressfirst: '',
        aadhaarcardfirst: '',
        voterIDfirst: '',
        pancardfirst: '',
        //second director
        fullnamesecond: '',
        emailidsecond: '',
        mobilenumbersecond: '',
        addresssecond: '',
        aadhaarcardsecond: '',
        voterIDsecond: '',
        pancardsecond: '',
        //third director
        fullnamethird: '',
        emailidthird: '',
        mobilenumberthird: '',
        addressthird: '',
        aadhaarcardthird: '',
        voterIDthird: '',
        pancardthird: '',
        //fourth director
        fullnamefourth: '',
        emailidfourth: '',
        mobilenumberfourth: '',
        addressfourth: '',
        aadhaarcardfourth: '',
        voterIDfourth: '',
        pancardfourth: '',
    });
    let [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (numcount === 1) {
            if (!formData.fullnamefirst) {
                setErrorMessage("First Director Name is required.")
            } else if (!formData.emailidfirst) {
                setErrorMessage("First Director Email Id is required.")
            } else if (!formData.mobilenumberfirst) {
                setErrorMessage("First Director Mobile Number is required.")
            } else if (!formData.addressfirst) {
                setErrorMessage("First Director Address is required.")
            } else if (!formData.aadhaarcardfirst) {
                setErrorMessage("First Director Aadhaar Card is required.")
            } else if (!formData.voterIDfirst) {
                setErrorMessage("First Director Voter ID is required.")
            } else if (!formData.pancardfirst) {
                setErrorMessage("First Director PAN Card is required.")
            } else {
                setErrorMessage('')
            }
        } else if (numcount === 2) {
            if (!formData.fullnamefirst) {
                setErrorMessage("First Director Name is required.")
            } else if (!formData.emailidfirst) {
                setErrorMessage("First Director Email Id is required.")
            } else if (!formData.mobilenumberfirst) {
                setErrorMessage("First Director Mobile Number is required.")
            } else if (!formData.addressfirst) {
                setErrorMessage("First Director Address is required.")
            } else if (!formData.aadhaarcardfirst) {
                setErrorMessage("First Director Aadhaar Card is required.")
            } else if (!formData.voterIDfirst) {
                setErrorMessage("First Director Voter ID is required.")
            } else if (!formData.pancardfirst) {
                setErrorMessage("First Director PAN Card is required.")
            } else if (!formData.fullnamesecond) {
                setErrorMessage("Second Director Name is required.")
            } else if (!formData.emailidsecond) {
                setErrorMessage("Second Director Email Id is required.")
            } else if (!formData.mobilenumbersecond) {
                setErrorMessage("Second Director Mobile Number is required.")
            } else if (!formData.addresssecond) {
                setErrorMessage("Second Director Address is required.")
            } else if (!formData.aadhaarcardsecond) {
                setErrorMessage("Second Director Aadhaar Card is required.")
            } else if (!formData.voterIDsecond) {
                setErrorMessage("Second Director Voter ID is required.")
            } else if (!formData.pancardsecond) {
                setErrorMessage("Second Director PAN Card is required.")
            } else {
                setErrorMessage('')
            }
        } else if (numcount === 3) {
            if (!formData.fullnamefirst) {
                setErrorMessage("First Director Name is required.")
            } else if (!formData.emailidfirst) {
                setErrorMessage("First Director Email Id is required.")
            } else if (!formData.mobilenumberfirst) {
                setErrorMessage("First Director Mobile Number is required.")
            } else if (!formData.addressfirst) {
                setErrorMessage("First Director Address is required.")
            } else if (!formData.aadhaarcardfirst) {
                setErrorMessage("First Director Aadhaar Card is required.")
            } else if (!formData.voterIDfirst) {
                setErrorMessage("First Director Voter ID is required.")
            } else if (!formData.pancardfirst) {
                setErrorMessage("First Director PAN Card is required.")
            } else if (!formData.fullnamesecond) {
                setErrorMessage("Second Director Name is required.")
            } else if (!formData.emailidsecond) {
                setErrorMessage("Second Director Email Id is required.")
            } else if (!formData.mobilenumbersecond) {
                setErrorMessage("Second Director Mobile Number is required.")
            } else if (!formData.addresssecond) {
                setErrorMessage("Second Director Address is required.")
            } else if (!formData.aadhaarcardsecond) {
                setErrorMessage("Second Director Aadhaar Card is required.")
            } else if (!formData.voterIDsecond) {
                setErrorMessage("Second Director Voter ID is required.")
            } else if (!formData.pancardsecond) {
                setErrorMessage("Second Director PAN Card is required.")
            } else if (!formData.fullnamethird) {
                setErrorMessage("Third Director Name is required.")
            } else if (!formData.emailidthird) {
                setErrorMessage("Third Director Email Id is required.")
            } else if (!formData.mobilenumberthird) {
                setErrorMessage("Third Director Mobile Number is required.")
            } else if (!formData.addressthird) {
                setErrorMessage("Third Director Address is required.")
            } else if (!formData.aadhaarcardthird) {
                setErrorMessage("Third Director Aadhaar Card is required.")
            } else if (!formData.voterIDthird) {
                setErrorMessage("Third Director Voter ID is required.")
            } else if (!formData.pancardthird) {
                setErrorMessage("Third Director PAN Card is required.")
            } else {
                setErrorMessage('')
            }
        } else if (numcount === 4) {
            if (!formData.fullnamefirst) {
                setErrorMessage("First Director Name is required.")
            } else if (!formData.emailidfirst) {
                setErrorMessage("First Director Email Id is required.")
            } else if (!formData.mobilenumberfirst) {
                setErrorMessage("First Director Mobile Number is required.")
            } else if (!formData.addressfirst) {
                setErrorMessage("First Director Address is required.")
            } else if (!formData.aadhaarcardfirst) {
                setErrorMessage("First Director Aadhaar Card is required.")
            } else if (!formData.voterIDfirst) {
                setErrorMessage("First Director Voter ID is required.")
            } else if (!formData.pancardfirst) {
                setErrorMessage("First Director PAN Card is required.")
            } else if (!formData.fullnamesecond) {
                setErrorMessage("Second Director Name is required.")
            } else if (!formData.emailidsecond) {
                setErrorMessage("Second Director Email Id is required.")
            } else if (!formData.mobilenumbersecond) {
                setErrorMessage("Second Director Mobile Number is required.")
            } else if (!formData.addresssecond) {
                setErrorMessage("Second Director Address is required.")
            } else if (!formData.aadhaarcardsecond) {
                setErrorMessage("Second Director Aadhaar Card is required.")
            } else if (!formData.voterIDsecond) {
                setErrorMessage("Second Director Voter ID is required.")
            } else if (!formData.pancardsecond) {
                setErrorMessage("Second Director PAN Card is required.")
            } else if (!formData.fullnamethird) {
                setErrorMessage("Third Director Name is required.")
            } else if (!formData.emailidthird) {
                setErrorMessage("Third Director Email Id is required.")
            } else if (!formData.mobilenumberthird) {
                setErrorMessage("Third Director Mobile Number is required.")
            } else if (!formData.addressthird) {
                setErrorMessage("Third Director Address is required.")
            } else if (!formData.aadhaarcardthird) {
                setErrorMessage("Third Director Aadhaar Card is required.")
            } else if (!formData.voterIDthird) {
                setErrorMessage("Third Director Voter ID is required.")
            } else if (!formData.pancardthird) {
                setErrorMessage("Third Director PAN Card is required.")
            } else if (!formData.fullnamefourth) {
                setErrorMessage("Fourth Director Name is required.")
            } else if (!formData.emailidfourth) {
                setErrorMessage("Fourth Director Email Id is required.")
            } else if (!formData.mobilenumberfourth) {
                setErrorMessage("Fourth Director Mobile Number is required.")
            } else if (!formData.addressfourth) {
                setErrorMessage("Fourth Director Address is required.")
            } else if (!formData.aadhaarcardfourth) {
                setErrorMessage("Fourth Director Aadhaar Card is required.")
            } else if (!formData.voterIDfourth) {
                setErrorMessage("Fourth Director Voter ID is required.")
            } else if (!formData.pancardfourth) {
                setErrorMessage("Fourth Director PAN Card is required.")
            } else {
                setErrorMessage('')
            }
        }
        setLoading(true)
        if (!errorMessage) {
            let url = process.env.REACT_APP_BASEURL + "/api/v1/challan";
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    "x-api-key": process.env.REACT_APP_X_API_KEY,
                },
            };
            let body = {
                //first director
                fullnamefirst: formData.fullnamefirst,
                emailidfirst: formData.emailidfirst,
                mobilenumberfirst: formData.mobilenumberfirst,
                addressfirst: formData.addressfirst,
                aadhaarcardfirst: formData.aadhaarcardfirst,
                voterIDfirst: formData.voterIDfirst,
                pancardfirst: formData.pancardfirst,
                //second director
                fullnamesecond: formData.fullnamesecond,
                emailidsecond: formData.emailidsecond,
                mobilenumbersecond: formData.mobilenumbersecond,
                addresssecond: formData.addresssecond,
                aadhaarcardsecond: formData.aadhaarcardsecond,
                voterIDsecond: formData.voterIDsecond,
                pancardsecond: formData.pancardsecond,
                //third director
                fullnamethird: formData.fullnamethird,
                emailidthird: formData.emailidthird,
                mobilenumberthird: formData.mobilenumberthird,
                addressthird: formData.addressthird,
                aadhaarcardthird: formData.aadhaarcardthird,
                voterIDthird: formData.voterIDthird,
                pancardthird: formData.pancardthird,
                //fourth director
                fullnamefourth: formData.fullnamefourth,
                emailidfourth: formData.emailidfourth,
                mobilenumberfourth: formData.mobilenumberfourth,
                addressfourth: formData.addressfourth,
                aadhaarcardfourth: formData.aadhaarcardfourth,
                voterIDfourth: formData.voterIDfourth,
                pancardfourth: formData.pancardfourth,
            }
            console.log(body)
            axios.post(url, body, config).then((res) => {
                setLoading(false);
                console.log("Result" + res.data)
            }).catch((err) => {
                setLoading(false)
                console.log(err);
            })
        } else {
            setLoading(false)
        }
    }
    const addComponent = () => {
        const newArray = [];
        for (let i = 1; i <= numcount; i++) {
            newArray.push(<div>
                <Row className="mt-3">
                    <div className="fs-4">Director-{i} Details/Documents<br /><p className="small">Upload Clear & colour scans</p></div>
                    <Col lg="6">
                        <FormGroup>
                            <Label>Full Name</Label>
                            <Input type="text" name={numcount === 1 ? "fullnamefirst" : numcount === 2 ? "fullnamesecond" : numcount === 3 ? "fullnamethird" : numcount === 4 ? "fullnamefourth" : ""}
                                onChange={(e) => {
                                    if (i === 1) {
                                        setFormData({ ...formData, fullnamefirst: e.target.value })
                                    } else if (i === 2) {
                                        setFormData({ ...formData, fullnamesecond: e.target.value })
                                    } else if (i === 3) {
                                        setFormData({ ...formData, fullnamethird: e.target.value })
                                    } else {
                                        setFormData({ ...formData, fullnamefourth: e.target.value })
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <Label>Email Id</Label>
                            <Input type="email" name={numcount === 1 ? "emailidfirst" : numcount === 2 ? "emailidsecond" : numcount === 3 ? "emailidthird" : numcount === 4 ? "emailidfourth" : ""}
                                onChange={(e) => {
                                    if (i === 1) {
                                        setFormData({ ...formData, emailidfirst: e.target.value })
                                    } else if (i === 2) {
                                        setFormData({ ...formData, emailidsecond: e.target.value })
                                    } else if (i === 3) {
                                        setFormData({ ...formData, emailidthird: e.target.value })
                                    } else if (i === 4) {
                                        setFormData({ ...formData, emailidfourth: e.target.value })
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg="6">
                        <FormGroup>
                            <Label>Mobile Number</Label>
                            <Input type="text" name={numcount === 1 ? "mobilenumberfirst" : numcount === 2 ? "mobilenumbersecond" : numcount === 3 ? "mobilenumberthird" : numcount === 4 ? "mobilenumberfourth" : ""}
                                onChange={(e) => {
                                    if (i === 1) {
                                        setFormData({ ...formData, mobilenumberfirst: e.target.value })
                                    } else if (i === 2) {
                                        setFormData({ ...formData, mobilenumbersecond: e.target.value })
                                    } else if (i === 3) {
                                        setFormData({ ...formData, mobilenumberthird: e.target.value })
                                    } else {
                                        setFormData({ ...formData, mobilenumberfourth: e.target.value })
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <Label>Address</Label>
                            <Input type="text" name={numcount === 1 ? "addressfirst" : numcount === 2 ? "addresssecond" : numcount === 3 ? "addressthird" : numcount === 4 ? "addressfourth" : ""}
                                onChange={(e) => {
                                    if (i === 1) {
                                        setFormData({ ...formData, addressfirst: e.target.value })
                                    } else if (i === 2) {
                                        setFormData({ ...formData, addresssecond: e.target.value })
                                    } else if (i === 3) {
                                        setFormData({ ...formData, addressthird: e.target.value })
                                    } else {
                                        setFormData({ ...formData, addressfourth: e.target.value })
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg="4">
                        <FormGroup>
                            <Label>
                                Aadhaar Card (Colour & Clear)
                            </Label>
                            <Input
                                name={numcount === 1 ? "aadhaarcardfirst" : numcount === 2 ? "aadhaarcardsecond" : numcount === 3 ? "aadhaarcardthird" : numcount === 4 ? "aadhaarcardfourth" : ""}
                                type="file"
                                onChange={(e) => {
                                    if (i === 1) {
                                        setFormData({ ...formData, aadhaarcardfirst: e.target.value })
                                    } else if (i === 2) {
                                        setFormData({ ...formData, aadhaarcardsecond: e.target.value })
                                    } else if (i === 3) {
                                        setFormData({ ...formData, aadhaarcardthird: e.target.value })
                                    } else {
                                        setFormData({ ...formData, aadhaarcardfourth: e.target.value })
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>
                                Voter ID (Colour & Clear)
                            </Label>
                            <Input
                                name={numcount === 1 ? "voterIDfirst" : numcount === 2 ? "voterIDsecond" : numcount === 3 ? "voterIDthird" : numcount === 4 ? "voterIDfourth" : ""}
                                type="file"
                                onChange={(e) => {
                                    if (i === 1) {
                                        setFormData({ ...formData, voterIDfirst: e.target.value })
                                    } else if (i === 2) {
                                        setFormData({ ...formData, voterIDsecond: e.target.value })
                                    } else if (i === 3) {
                                        setFormData({ ...formData, voterIDthird: e.target.value })
                                    } else {
                                        setFormData({ ...formData, voterIDfourth: e.target.value })
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>
                                PAN Card (Colour & Clear)
                            </Label>
                            <Input
                                name={numcount === 1 ? "pancardfirst" : numcount === 2 ? "pancardsecond" : numcount === 3 ? "pancardthird" : numcount === 4 ? "pancardfourth" : ""}
                                type="file"
                                onChange={(e) => {
                                    if (i === 1) {
                                        setFormData({ ...formData, pancardfirst: e.target.value })
                                    } else if (i === 2) {
                                        setFormData({ ...formData, pancardsecond: e.target.value })
                                    } else if (i === 3) {
                                        setFormData({ ...formData, pancardthird: e.target.value })
                                    } else {
                                        setFormData({ ...formData, pancardfourth: e.target.value })
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
            </div>)
        }
        return newArray
    };
    return (
        <>
            <div className="container">
                <Row>
                    <Col className="header-color" style={{ padding: '20px 20px 60px 20px', }}>
                        <h3 className="text-white" style={{ fontWeight: "bold" }}><BsDownload /> Directors Details</h3>
                    </Col>
                </Row>
                <Row style={{ marginTop: '-50px' }} >
                    <Col lg={12}>
                        <Card >
                            <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '15px' }}>
                                <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                                    <div style={{ marginLeft: '20px', fontWeight: "bold" }}>Directors Details</div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit} >
                                    {errorMessage &&
                                        <Col className="mt-1 " >
                                            <div className="alert alert-danger d-flex justify-content-between" role="alert">
                                                <div>{errorMessage}</div>
                                                <div>
                                                    <Button onClick={() => setErrorMessage('')} type="submit" className="close left " style={{ background: 'transparent', border: 'none' }} aria-label="Close">
                                                        <span aria-hidden="true" style={{ fontSize: '18px', fontWeight: 'bold', color: 'red' }}>&times;</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                    {addComponent()}
                                    <div className="button-group">
                                        <Button onClick={() => { navigate("/userOnbording") }} className="btn header-color" style={{ borderColor: '#0149AD' }}>Prev</Button>
                                        {loading ?
                                            <Button type="submit" className="btn header-color" style={{ borderColor: '#0149AD' }} disabled>Submit...
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            </Button> :
                                            <Button type="submit" className="btn header-color" style={{ borderColor: '#0149AD' }}>Submit</Button>
                                        }
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row >
            </div>
        </>
    )
}

export default UserDirector