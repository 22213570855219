// import React, { useEffect, useState } from "react";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import { CiMobile4 } from "react-icons/ci"
// import { Col, Row } from "reactstrap";
// import Card from 'react-bootstrap/Card';
// import Description from "./Description";
// import "./Account.css"
// import LinkPayee from "./LinkPayee";
// import AccountDocument from "./AccountDoc";

import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { CiMobile4 } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import "./Account.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";
import { AccountListData } from "./AccountListData";
import CustomPagination from "../Pagination/CustomPagination";
const AccountTabs = () => {
  // const [key, setKey] = useState('description');
  // const [query, setQuery] = useState();
  // useEffect(() => {
  //     if(query){
  //         console.log("AJSagjs " + query);
  //         setKey("linkpayee")
  //     }
  // }, [query])
  let navigate = useNavigate();
  let PageSize = 10;

  useEffect(() => {
    getAccountList('');
  }, []);


  const [accountList, setAccountList] = useState(AccountListData());

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('')



  const getAccountList = (searchData) => {

    if (searchData) {
      var searchparam = '?page=' + currentPage + '&_project=' + searchData
    } else {
      searchparam = '?page=' + currentPage
    }
    let url = process.env.REACT_APP_BASEURL + "/api/v1/account" + searchparam;
    const getlocalStorage = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // "Accept" : "plain/text",
        Authorization: "Bearer " + getlocalStorage.token,
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        if (res) {
          console.log("RESSSSSSS", JSON.stringify(res));
          // localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
        }
      })
      .catch((error) => {
        // const getErrRes = errorHandler(error);
        // if (getErrRes === 401) {
        //   toast.error("Something went wrong.", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 2000,
        //     theme: "colored",
        //   });
        //   navigate("/");
        // } else {
        //   toast.error(getErrRes ? getErrRes : "Something went wrong.", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 2000,
        //     theme: "colored",
        //   });
        // }
      });
  };

  useMemo(() => {
    getAccountList(searchValue)
    // const firstPageIndex = (currentPage - 1) * PageSize;
    // const lastPageIndex = firstPageIndex + PageSize;
    // // console.log("firstPageIndex",firstPageIndex);
    // // console.log("PageSize",PageSize);
    // // console.log("lastPageIndex",lastPageIndex);
    // return accountList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);



  const handlePageClick = () => {
    console.log("DATA>>>> CALL >>> handlePageClick");
  }

  const onSearch = () => {
    if (searchValue) {
      getAccountList(searchValue)
    }
  }
  return (
    <>
      {/* <Row>
                <Col className="header-color" style={{ padding: '20px 20px 100px 20px', }}>
                    <h3 className="text-white" style={{ fontWeight: "bold" }}><CiMobile4/> Add New Account</h3>
                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                    <Card.Body  style={{  padding: '0px' }} >
                            <Tabs defaultActiveKey="description" id="uncontrolled-tab-example"
                            className="subheader-color"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    style={{marginTop:'-15px',}}>
                                <Tab eventKey="description" title="Description"><Description onQuery={setQuery}/></Tab>
                                <Tab eventKey="linkpayee" title="Link Payee"><LinkPayee query={query}/></Tab>
                                <Tab eventKey="documents" title="Documents" ><AccountDocument/></Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
             */}
      <Row>
        <Col className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            {" "}
            <CiMobile4 /> Payin Report
          </h3>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card >
            <Card.Header className="subheader-color" style={{ padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                <div style={{ fontWeight: 'bold' }}> Payin Report</div>
                {/* <div style={{ fontWeight: 'bold',display:'flex' }}>Auto Transfer Accounts &nbsp;<Form.Check type="switch"id="custom-switch" />
              </div> */}
                {/* <div style={{ fontWeight: 'bold', display: 'flex' }}>
                  <div className="me-2" style={{ display: 'bolck' }}>
                    <label>From Date</label>
                    <Input
                      name="date"
                      type="date"
                      placeholder="Date"
                    />
                  </div>
                  <div style={{ display: 'bolck' }}>
                    <label>To Date</label>
                    <Input
                      name="date"
                      type="date"
                      placeholder="Date"
                    />
                  </div>
                  <div className="mt-4">
                  <Button className="header-color rounded-pill"
                    style={{
                      // borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                    }}
                  >
                    Search
                  </Button>
                  <Button className="header-color rounded-pill"
                    style={{
                      // borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                    }}
                  >
                    Reset
                  </Button>
                  </div>
                </div> */}
              </div>
            </Card.Header>
            <Card.Body>
              {/* <Row className="mt-3">
                <Col xs="6">
                  <Input
                    value={searchValue}
                    onChange={(e) => { setSearchValue(e.target.value) }} name="search"
                    type="text"
                    placeholder="Search by Account Name,Account ID"
                  />
                </Col>
                <Col xs="6">
                  <Button
                    onClick={() => onSearch()}
                    style={{
                      backgroundColor: "#a2751a",
                      border: "none",
                      marginRight: "5px",
                    }}
                  >
                    <FaSearch />{" "}
                  </Button>
                  <span
                    onClick={() => {
                      setSearchValue('')
                      getAccountList('')
                    }
                    }
                    style={{ color: "#0149AD", marginRight: "5px" }}>
                    {" "}
                    clear
                  </span>
                  <Button className="header-color rounded-pill"
                    style={{
                      // borderRadius: "30%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                    }}
                  >
                    Copy
                  </Button>
                  <Button className="header-color rounded-pill"
                    style={{
                      // borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                    }}
                  >
                    Excel
                  </Button>
                  <Button className="header-color rounded-pill"
                    style={{
                      // borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                    }}
                  >
                    PDF
                  </Button>
                  <Button className="header-color rounded-pill"
                    style={{
                      // borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                    }}
                  >
                    Column Visibility
                  </Button>
                </Col>
              </Row> */}
              <Row className="mt-3 p-0">
                <Col lg={5} className="p-1 ps-2">
                  <Input
                    value={searchValue}
                    onChange={(e) => { setSearchValue(e.target.value) }} name="search"
                    type="text"
                    placeholder="Search by Account Name,Account ID"
                  />
                </Col>
                <Col className="p-1">
                  <Input
                    name="dateFrom"
                    // value={filterParams.dateFrom}
                    // onChange={(e) => {
                    //   setFilterParams({
                    //     ...filterParams,
                    //     dateFrom: e.target.value,
                    //   });
                    // }}
                    type="date"
                  />
                </Col>
                <Col className="p-1">
                  <Input
                    name="dateTo"
                    // value={filterParams.dateTo}
                    // onChange={(e) => {
                    //   setFilterParams({
                    //     ...filterParams,
                    //     dateTo: e.target.value,
                    //   });
                    // }}
                    type="date"
                  />
                </Col>
                <Col>
                  <Button
                    onClick={() => onSearch()}
                    style={{
                      backgroundColor: "#a2751a",
                      border: "none",
                      marginRight: "10px",
                    }}
                  >
                    <FaSearch />{" "}
                  </Button>
                  <span
                    // onClick={() => {
                    //   setFilterParams({
                    //     dateFrom: "",
                    //     dateTo: "",
                    //     s: "",
                    //     accountId: "",
                    //   });
                    //   getFundDepositList();
                    // }}
                    style={{ color: "#0149AD", marginRight: "10px" }}
                  >
                    {" "}
                    clear
                  </span>
                  <Button
                    style={{
                      backgroundColor: "#0149AD",
                      borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                    }}
                  >
                    <AiOutlineArrowDown />{" "}
                  </Button>
                </Col>
              </Row>
              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Account Name</th>
                    <th>Account Number</th>
                    <th>IFSC</th>
                    <th>Account Creation Date</th>
                    <th>Account Status</th>
                    <th>Available Balance </th>
                  </tr>
                </thead>
                <tbody>
                  {accountList?.length &&
                    accountList?.length > 0 &&
                    accountList.map((item, index) => {
                      return (
                        <tr className="border-top">
                          <td>{item.name}</td>
                          <td>{item.hardEscrowAccountNumber}</td>
                          <td>{item.ifsc}</td>
                          <td>{item.createdAt}</td>
                          <td>{item.bank}</td>
                          <td>{item.balance}</td>
                        </tr>
                      );
                    })}

                  {accountList.length === 0 && <h4>NO DATA Available</h4>}
                </tbody>
              </Table>
              {accountList?.length > 10 &&
                <CustomPagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={accountList.length}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AccountTabs;