import React, { useEffect, useState } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { CiMobile4 } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import { BsFunnel } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import axios from "axios";
import DateTimePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import { BsFillCalendarEventFill } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TransactionHistory = () => {
    const [transferhistory, setTransferhistory] = useState([]);
    const [endDate, setEndDate] = useState(new Date())
    const [starDate, setStarDate] = useState(new Date(endDate))
    const [status, setStatus] = useState(false)
    const [show, setShow] = useState(false);
    const [viewdata, setViewdata] = useState([])
    const [terminalid, setTerminalid] = useState('')
    const [extTransaction, setExtTransaction] = useState('')

    const handleClose = () => {setShow(false);setExtTransaction('');setTerminalid(''); setStatus('')}
    const handleShow = () => setShow(true);
    starDate.setMonth(endDate.getMonth() - 3);
    const today = moment();
    const minDate = moment(today).subtract(3, "months");
    const maxDate = today;

    const handleDateTimeChange = date => {
        setStarDate(date);
    };

    const handleDateTimeChangeEnd = date => {
        setEndDate(date);
    };

    const dateTimeToSend = starDate;
    const dateTimeToSendEnd = endDate;

    useEffect(() => {
        if (dateTimeToSend && dateTimeToSendEnd !== "") {
            console.log("hjkhkjh")
            sendDates()
        }
    }, [dateTimeToSend, dateTimeToSendEnd])



    const sendDates = (e) => {
        // e.preventDefault();
        // let error = 0;

        // if (addData.amount === '') {
        //     setAmounterr('amount is required');
        //     error = 1;
        // }
        // if (addData.remark === '') {
        //     setREmarkerr('remark is required');
        //     error = 1;
        // }
        // if (addData.customerName === '') {
        //     setCustomernameerr('Name is required');
        //     error = 1;
        // }
        // if (error === 0) {
        // setLoading(true);
        let body = {
            startDate: moment(dateTimeToSend).format("YYYY-MM-DD hh:mm:ss"),
            endDate: moment(dateTimeToSendEnd).format("YYYY-MM-DD hh:mm:ss"),
            pageSize: "10",
            pageNo: "1",
        }
        console.log(body)

        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
                "Authorization": localStorage.getItem('escrowToken')

            },
        };

        let url = process.env.REACT_APP_BASEURL + `/v1/TransactionReport/`
        console.log(url)
        axios.post(url, body, config).then((res) => {
            // setLoading(false);
            setTransferhistory(res.data.data.data)
            console.log(res.data.data.data);
            // toast.success("Verify successfully", {
            //     autoClose: 3000,
            //     theme: "colored",
            // });


        }).catch((err) => {
            // setLoading(false);
            console.log(err);

            // toast.error(err.response.data.message, {
            //     autoClose: 3000,
            //     theme: "colored",
            // });
        });
        // }

    };

    const checkstatus = (extTransactionId) => {
        setShow(true)
        let body = {
            extTransactionId: extTransactionId
        }
        console.log(body)

        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
                "Authorization": localStorage.getItem('escrowToken')

            },
        };

        let url = process.env.REACT_APP_BASEURL + `/v1/TransactionStatus/`
        console.log(url)
        axios.post(url, body, config).then((res) => {
            // setLoading(false);
            // setTransferhistory(res.data.data)
            setTerminalid(res.data.data.terminalId)
            setExtTransaction(res.data.data.extTransactionId)
            setStatus(res.data.data.status)
            setViewdata(res.data)
            // setTxntime(res.data.data.txnTime)
            // setRespmessge(res.data.data.respMessge)

            console.log("statusddffd", res.data.data);
            // toast.success("Verify successfully", {
            //     autoClose: 3000,
            //     theme: "colored",
            // });


        }).catch((err) => {
            // setLoading(false);
            console.log(err);

            // toast.error(err.response.data.message, {
            //     autoClose: 3000,
            //     theme: "colored",
            // });
        });
        // }

    };

    return (
        <>
            <Row>
                <Col className="header-color"
                    style={{ padding: "20px 20px 100px 20px", }}>
                    <h3 className="text-white" style={{ fontWeight: "bold" }}>
                        <CiMobile4></CiMobile4>Transaction History
                    </h3>
                </Col>
            </Row>
            <Row style={{ marginTop: "-50px" }}>
                <Col lg={12}>
                    <Card>
                        <Card.Header className="subheader-color" style={{ backgroundColor: "", padding: "15px" }}>
                            <Row className="mt-2 d-flex justify-content-center ">

                                <Col sm="2">
                                    <DatePicker
                                        selected={starDate}
                                        onChange={handleDateTimeChange}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        placeholderText="Start date"
                                        minDate={minDate.toDate()}
                                        maxDate={maxDate.toDate()}
                                    />
                                    <br />
                                </Col>
                                <Col sm="2">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleDateTimeChangeEnd}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        placeholderText="End date"
                                        minDate={minDate.toDate()}
                                        maxDate={maxDate.toDate()}
                                    />
                                </Col>

                                {/* <Button onClick={()=>{sendDates()}}>click</Button> */}
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table className="no-wrap mt-3 align-middle border-top" responsive borderless>
                                <thead>
                                    <tr>
                                        <th>Transaction Date</th>
                                        <th>Customer Name</th>
                                        <th>Terminal ID</th>
                                        <th>UpiId</th>
                                        <th>Amount</th>
                                        <th> Status</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transferhistory?.length > 0 &&
                                        transferhistory.map((item, index) => {
                                            console.log(item)
                                            return (
                                                <>
                                                    <tr key={index} className="border-top">
                                                        <td>{item.txnTime}</td>
                                                        <td>{item.customerName}</td>
                                                        <td>{item.terminalId}</td>
                                                        <td>{item.upiId}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.respMessge}</td>
                                                        <td>
                                                            <Button variant="primary" onClick={() => checkstatus(item.extTransactionId)}>
                                                                View
                                                            </Button></td>

                                                    </tr>
                                                </>
                                            );
                                        })}
                                    {transferhistory?.length === 0 && <h4 className="">No Data Available</h4>}
                                </tbody>

                            </Table>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Detail</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="d-flex"><p style={{ fontWeight: 'bold' }}>extTransaction Id:-</p>&nbsp;&nbsp;<span>{extTransaction}</span></div>
                                    <div className="d-flex"><p style={{ fontWeight: 'bold' }}>Terminal id:</p>&nbsp;&nbsp;<span>{terminalid}</span></div>
                                    <div className="d-flex"><p style={{ fontWeight: 'bold' }}>Status:</p>&nbsp;&nbsp;<span>{status}</span></div>
                                    {/* <div className="d-flex"><p style={{ fontWeight: 'bold' }}>Time:</p>&nbsp;&nbsp;<span>{txnTime}</span></div> */}
                                    {/* <div className="d-flex"> <p style={{fontWeight:'bold'}}>Response Msg:</p>&nbsp;&nbsp;<span>
                                    {viewdata.map((data)=>{
                                        return(
                                            <>
                                            <span>{data.respMessge}</span></>
                                        )
                                    })}</span></div>
                                    <div className="d-flex"><p style={{fontWeight:'bold'}}>TxnTime:</p>&nbsp;&nbsp;<span>
                                    {viewdata.map((data)=>{
                                        return(
                                            <>
                                            <span>{data.txnTime}</span></>
                                        )
                                    })}</span></div> */}

                                </Modal.Body>
                                {/* <Modal.Footer>
                                    <Button style={{ backgroundColor: '#23528b',border:'none    ' }} onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer> */}
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default TransactionHistory;

