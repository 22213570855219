import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Table, Input } from "reactstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Link, useParams ,useNavigate} from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';


const UpdateAdmin = () => {
  const [adminList, setAdminList] = useState([]);
  let [loading, setLoading] = useState(false);
  let navigate = useNavigate()
  let [data, setData] = useState({
    name: '',
    email: '',
    Phone_number: '',
  })

  let { id } = useParams()

  useEffect(() => {
    AdminList()
  }, []);

  const AdminList = () => {
    let url = process.env.REACT_APP_BASEURL + `/v1/asset/${id}/`
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": localStorage.getItem('escrowToken')
      }
    };

    axios.get(url, config)
      .then(res => {
        console.log("fghgfj", res.data)
        setAdminList(res.data.data);
        setData({
          name: res.data.data.name,
          Phone_number: res.data.data.phone_number,
          email: res.data.data.username,

        })
      }
      ).catch(err => {

      })
  }

  const updateAdminList = (e) => {
    e.preventDefault();

    let config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": localStorage.getItem('escrowToken')

      },
    };

    let url = process.env.REACT_APP_BASEURL + `/v1/asset/${id}/`
    const formData = new FormData();

    setLoading(true);

    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone_number", data.Phone_number);


    axios.patch(url, formData, config).then((res) => {
      setData({ ...data, })
      setLoading(false);

      toast.success("SubAdmin Update successfully", {
        autoClose: 3000,
        theme: "colored",
      });
      navigate("/admin");

    }).catch((err) => {
      setLoading(false);
      toast.error(err.response.data.message, {
        autoClose: 3000,
        theme: "colored",
      });
    });

  };

  return (
    <>
      <Row>
        <Col className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h2 className="text-white">
            {" "}
            Update Sub-Admin
          </h2>
          <span className="text-white"> </span>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card >
            <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD', fontWeight: 'bold' }}>
                <div style={{ marginLeft: '20px' }}>Add Sub-Admin Form</div>
                <div style={{ marginRight: '20px', color: '#0149AD', fontWeight: '400' }}>
                  <nav aria-label="breadcrumb" >
                    <ol className="breadcrumb ml-5 ">
                      <li className="breadcrumb-item"><Link to="/admin" style={{ textDecoration: "none", color: '#1b385b' }}>Sub Admin</Link></li>
                      <li className="breadcrumb-item active" aria-current="page" ><Link style={{ textDecoration: "none", }}>Add Sub Admin</Link></li>
                    </ol>
                  </nav>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={updateAdminList}>

                <Row className="mt-3">
                  <Col>
                    <label>Name</label>
                    <Input
                      name="name"
                      type="text"
                      placeholder="Name"
                      className="mt-2"
                      defaultValue={data.name}
                      // onChange={(e)=>{setData({name:e.target.value})}}
                      onChange={(e) => { setData({ ...data, name: e.target.value }); }}

                    />
                  </Col>
                  <Col>
                    <label>Email</label>
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="mt-2"
                      value={data.email}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={6} sm={6}>
                    <label>Mobile no</label>
                    <Input
                      name="mobile"
                      type="number"
                      placeholder="Mobile No"
                      className="mt-2"
                      defaultValue={data.Phone_number}
                      onChange={(e) => { setData({ ...data, Phone_number: e.target.value }); }}

                    // onChange={(e)=>{setData({Phone_number:e.target.value})}}

                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                  {loading ?
                      <Button type="submit" className="btn header-color" style={{ borderColor: '#0149AD' }} disabled>Submit...
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                      </Button> :

                      <Button type="submit" className="btn header-color" style={{ borderColor: '#0149AD' }}>Submit</Button>
                    }
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default UpdateAdmin
