import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Button, Table, Input, Label, FormGroup } from "reactstrap";
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { FiSend } from "react-icons/fi";
import { CiMobile4 } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";
import { AccountListData } from "../Account/AccountListData";
import CustomPagination from "../Pagination/CustomPagination";
function SingleTransfer() {
    let navigate = useNavigate();
    let PageSize = 10;

    useEffect(() => {
        getAccountList('');
    }, []);


    const [accountList, setAccountList] = useState(AccountListData());

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('')



    const getAccountList = (searchData) => {

        if (searchData) {
            var searchparam = '?page=' + currentPage + '&_project=' + searchData
        } else {
            searchparam = '?page=' + currentPage
        }
        let url = process.env.REACT_APP_BASEURL + "/api/v1/account" + searchparam;
        const getlocalStorage = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                // "Accept" : "plain/text",
                Authorization: "Bearer " + getlocalStorage.token,
                "x-api-key": process.env.REACT_APP_X_API_KEY,
            },
        };
        axios
            .get(url, config)
            .then((res) => {
                if (res) {
                    console.log("RESSSSSSS", JSON.stringify(res));
                    // localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
                }
            })
            .catch((error) => {
                // const getErrRes = errorHandler(error);
                // if (getErrRes === 401) {
                //   toast.error("Something went wrong.", {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                //     theme: "colored",
                //   });
                //   navigate("/");
                // } else {
                //   toast.error(getErrRes ? getErrRes : "Something went wrong.", {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                //     theme: "colored",
                //   });
                // }
            });
    };

    useMemo(() => {
        getAccountList(searchValue)
        // const firstPageIndex = (currentPage - 1) * PageSize;
        // const lastPageIndex = firstPageIndex + PageSize;
        // // console.log("firstPageIndex",firstPageIndex);
        // // console.log("PageSize",PageSize);
        // // console.log("lastPageIndex",lastPageIndex);
        // return accountList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);



    const handlePageClick = () => {
        console.log("DATA>>>> CALL >>> handlePageClick");
    }

    const onSearch = () => {
        if (searchValue) {
            getAccountList(searchValue)
        }
    }

    // ==========================other=============================
    let [formData, setFormData] = useState({
        account: '',
        payee: '',
        amount: '',
        purpose: '',
    });
    let [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.account) {
            setErrorMessage('Account Name is required.')
        } else if (!formData.payee) {
            setErrorMessage('Payee Name is required.')
        } else if (!formData.amount) {
            setErrorMessage('Amount Name is required.')
        } else {
            setErrorMessage('')
        }
        setLoading(true)
        if (!errorMessage) {
            console.log("formData", formData);
            // const getlocalStorage = JSON.parse( localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY))

            // const config = {
            //     headers: {
            //         // "Access-Control-Allow-Origin": "*",
            //         // "Content-Type": "plain/text",
            //         // "Accept" : "plain/text",
            //         'Authorization' : 'Bearer ' + getlocalStorage.result.token,
            //         "x-api-key": process.env.REACT_APP_X_API_KEY,
            //     },
            // };
            // let body =  ({
            //     name: formData.accountName,
            //     email: formData.email,
            //     purpose: formData.purpose,
            //     startDate: formData.startDate,
            //     validity: formData.validity,
            //     expectedVolume: formData.expectedVolume,
            //     bank: "icici",
            //     documentIds: [
            //     "DC9039302951"
            //     ]
            //     })

            // let url = process.env.REACT_APP_BASEURL + "/api/v1/account"         

            // axios.post(url, body, config)
            //     .then(res => {
            //         setLoading(false);
            //         console.log("res", JSON.stringify(res));

            //     }).catch(error => {
            //         console.log("ERRRR",error);
            //         setLoading(false);
            //         if (error) {
            //             const getErrRes = errorHandler(error)
            //             // const getErrRes = ''
            //             if (getErrRes === 401) {
            //                 navigate("/");
            //             } else {
            //                 toast.error(getErrRes ? getErrRes : 'Something went wrong.', {
            //                     position: toast.POSITION.TOP_RIGHT,
            //                     autoClose: 3000,
            //                     theme: "colored",
            //                 });
            //             }
            //         }
            //     })
        } else {
            setLoading(false);
        }
    };

    // =========================modal===================================
    const [payinpopup, setPayinpopup] = useState({ selectoption: "" })
    const update = (e) => {
        setPayinpopup({
            [e.target.name]: e.target.value
        })
    }
    const [packagepopup, setPackagepopup] = useState(false)

    return (
        <>
            <Row>
                <Col className="header-color" style={{ padding: '20px 20px 100px 20px', }}>
                    <h2 className="text-white"> <FiSend />  Commission Management</h2>
                    {/* <span className="text-white">Create a new Transfer Entry </span> */}
                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                        <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
                            <div className="d-flex justify-content-between " style={{ color: '#0149AD', fontWeight: 'bold' }}>
                                <div style={{ marginLeft: '20px' }}> Commission Management</div>
                                <div>
                                    <Button className="header-color rounded-pill"
                                        style={{
                                            // borderRadius: "50%",
                                            borderColor: "#0149AD",
                                            marginLeft: "8px",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => { setPackagepopup(true) }}
                                    >
                                        Add New Package
                                    </Button>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                                <Col xs="6">
                                    <Input
                                        value={searchValue}
                                        onChange={(e) => { setSearchValue(e.target.value) }} name="search"
                                        type="text"
                                        placeholder="Search by ID"
                                    />
                                </Col>
                                <Col xs="6">
                                    <Button
                                        onClick={() => onSearch()}
                                        style={{
                                            backgroundColor: "#a2751a",
                                            border: "none",
                                            marginRight: "5px",
                                        }}
                                    >
                                        <FaSearch />{" "}
                                    </Button>
                                    <span
                                        onClick={() => {
                                            setSearchValue('')
                                            getAccountList('')
                                        }
                                        }
                                        style={{ color: "#0149AD", marginRight: "5px" }}>
                                        {" "}
                                        clear
                                    </span>
                                </Col>
                            </Row>
                            <Table
                                className="no-wrap mt-3 align-middle border-top"
                                responsive
                                borderless
                            >
                                <thead>
                                    <tr>
                                        <th className="text-center">ID.</th>
                                        <th className="text-center">Package Name</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Set Commission</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accountList?.length &&
                                        accountList?.length > 0 &&
                                        accountList.map((item, index) => {
                                            return (
                                                <tr className="border-top">
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">{item.balance}</td>
                                                    <td className="text-center"><Button className="header-color">Active</Button></td>
                                                    <td className="text-center">
                                                        <FormGroup>
                                                            <Input
                                                                id="dropdown"
                                                                name="selectoption"
                                                                type="select"
                                                                value={payinpopup.selectoption}
                                                                onChange={update}
                                                            >
                                                                <option value="selected" hidden>
                                                                    Select Type
                                                                </option>
                                                                <option value="payin">
                                                                    Payin
                                                                </option>
                                                                <option value="payout">
                                                                    Payout
                                                                </option>
                                                            </Input>
                                                        </FormGroup>
                                                    </td>
                                                    <td className="text-center"><MdDelete className="fs-4" style={{ cursor: "pointer" }} /></td>
                                                </tr>
                                            );
                                        })}

                                    {accountList.length === 0 && <h4>NO DATA Available</h4>}
                                </tbody>
                            </Table>
                            {accountList?.length > 10 &&
                                <CustomPagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={accountList.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* ============================Payin Popup=============================== */}
            {payinpopup.selectoption == "payin" ?
                <Modal show={payinpopup} onHide={() => { setPayinpopup(false) }} size="lg">
                    <Modal.Header closeButton>Payin Commission</Modal.Header>
                    <Modal.Body>
                        <Table
                            className="no-wrap mt-3 align-middle border-top"
                            responsive
                        >
                            <thead>
                                <tr>
                                    <th className="text-center">#ID</th>
                                    <th className="text-center">Slab</th>
                                    <th className="text-center">Type</th>
                                    <th className="text-center">Retailer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-top">
                                    <td className="text-center">1</td>
                                    <td className="text-center">110000</td>
                                    <td className="text-center pt-3 pb-0">
                                        <FormGroup>
                                            <Input
                                                name="select"
                                                type="select"
                                            >
                                                <option value="selected" hidden>
                                                    Select Type
                                                </option>
                                                <option value="percent">
                                                    Percent
                                                </option>
                                                <option value="flat">
                                                    Flat
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </td>
                                    <td className="text-center pt-3 pb-0">
                                        <FormGroup>
                                            <Input name="text" type="text" />
                                        </FormGroup>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="secondary" onClick={() => { setPayinpopup(false) }}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal> : ""
            }
            {/* ============================Payout Popup=============================== */}
            {payinpopup.selectoption == "payout" ?
                <Modal show={payinpopup} onHide={() => { setPayinpopup(false) }} size="xl">
                    <Modal.Header closeButton>Payout Commission</Modal.Header>
                    <Modal.Body>
                        <Table
                            className="no-wrap mt-3 align-middle border-top"
                            responsive
                        >
                            <thead>
                                <tr>
                                    <th className="text-center">#ID</th>
                                    <th className="text-center">Slab</th>
                                    <th className="text-center">Type</th>
                                    <th className="text-center">API Client</th>
                                    <th className="text-center">Master</th>
                                    <th className="text-center">Distributor</th>
                                    <th className="text-center">Retailer</th>
                                    <th className="text-center">Charges</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-top">
                                    <td className="text-center">1</td>
                                    <td className="text-center">110000</td>
                                    <td className="text-center pt-3 pb-0">
                                        <FormGroup>
                                            <Input
                                                name="select"
                                                type="select"
                                            >
                                                <option value="selected" hidden>
                                                    Select Type
                                                </option>
                                                <option value="percent">
                                                    Percent
                                                </option>
                                                <option value="flat">
                                                    Flat
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </td>
                                    <td className="text-center pt-3 pb-0">
                                        <FormGroup>
                                            <Input name="text" type="text" />
                                        </FormGroup>
                                    </td>
                                    <td className="text-center pt-3 pb-0">
                                        <FormGroup>
                                            <Input name="text" type="text" />
                                        </FormGroup>
                                    </td>
                                    <td className="text-center pt-3 pb-0">
                                        <FormGroup>
                                            <Input name="text" type="text" />
                                        </FormGroup>
                                    </td>
                                    <td className="text-center pt-3 pb-0">
                                        <FormGroup>
                                            <Input name="text" type="text" />
                                        </FormGroup>
                                    </td>
                                    <td className="text-center pt-3 pb-0">
                                        <FormGroup>
                                            <Input
                                                name="select"
                                                type="select"
                                            >
                                                <option value="selected" hidden>
                                                    Select Type
                                                </option>
                                                <option value="percent">
                                                    Percent
                                                </option>
                                                <option value="flat">
                                                    Flat
                                                </option>
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input name="text" type="text" />
                                        </FormGroup>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="secondary" onClick={() => { setPayinpopup(false) }}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal> : ""
            }
            {/* ============================Add Package Popup============================ */}
            <Modal show={packagepopup} onHide={() => { setPackagepopup(false); }} size="md">
                <Modal.Header closeButton>Add New Package</Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <Label>Package Name</Label>
                        <Input
                            name="select"
                            type="select"
                        >
                            <option value="selected" hidden>Enter Package Name</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </Input>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" onClick={() => { setPackagepopup(false); }}>Add</Button>
                    <Button color="secondary" onClick={() => { setPackagepopup(false); }}>Discard</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default SingleTransfer;