import React, { useState } from "react";
import { Col, Row, Input, FormGroup, Form, Label, Button } from "reactstrap";
import { BsDownload } from "react-icons/bs";
import Card from 'react-bootstrap/Card';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ChallanCreate = () => {
    let navigate = useNavigate();
    let [formData, setFormData] = useState({
        emailcompany: '',
        emailindividual: '',
        companypannumber: '',
        companyname: '',
        companytype: '',
        numberofDirectors: '',
        lineOfbusiness: '',
        ownerfullname: '',
        registeredaddress: '',
        registeredcity: '',
        registeredpin: '',
        correspondenceaddress: '',
        correspondencecity: '',
        correspondencepin: '',
        bankname: '',
        nameona_c: '',
        a_ctype: '',
        mobileno: '',
        accountno: '',
        ifsccode: '',
        coi: '',
        aoa: '',
        moa: '',
        gst: '',
        companypancard: '',
        companytancard: '',
        cancelledcheque: '',
        legalopinionletter: '',
        license: '',
        addressproof: '',
        bankstatement: '',
        merchantregstration: '',
        merchantcriminalundertaking: '',
        androidapplicationdetails: '',
        developerdetails: '',
        userloginURLdetails: '',
    });
    let [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.emailcompany) {
            setErrorMessage('Company Email is required.')
        } else if (!formData.emailindividual) {
            setErrorMessage("Individual Email is required.")
        } else if (!formData.companypannumber) {
            setErrorMessage("Company PAN Number is required.")
        } else if (!formData.companyname) {
            setErrorMessage("Company Name is required.")
        } else if (!formData.companytype) {
            setErrorMessage("Company Type is required.")
        } else if (!formData.numberofDirectors) {
            setErrorMessage("Number of Directors is required.")
        } else if (!formData.lineOfbusiness) {
            setErrorMessage("Line Of Business is required.")
        } else if (!formData.ownerfullname) {
            setErrorMessage("Owner Full Name is required.")
        } else if (!formData.registeredaddress) {
            setErrorMessage("Registered Address is required.")
        } else if (!formData.registeredcity) {
            setErrorMessage("Registered Address City is required.")
        } else if (!formData.registeredpin) {
            setErrorMessage("Registered Address PIN is required.")
        } else if (!formData.correspondenceaddress) {
            setErrorMessage("Correspondence Address is required.")
        } else if (!formData.correspondencecity) {
            setErrorMessage("Correspondence Address City is required.")
        } else if (!formData.correspondencepin) {
            setErrorMessage("Correspondence Address PIN is required.")
        } else if (!formData.bankname) {
            setErrorMessage("Bank Name is required.")
        } else if (!formData.nameona_c) {
            setErrorMessage("Name on A/c is required.")
        } else if (!formData.a_ctype) {
            setErrorMessage("A/c Type is required.")
        } else if (!formData.mobileno) {
            setErrorMessage("Mobile Number is required.")
        } else if (!formData.accountno) {
            setErrorMessage("Account Number is required.")
        } else if (!formData.ifsccode) {
            setErrorMessage("IFSC Code is required.")
        } else if (!formData.coi) {
            setErrorMessage("Certificate of Incorporation is required.")
        } else if (!formData.aoa) {
            setErrorMessage("Article of Association is required.")
        } else if (!formData.moa) {
            setErrorMessage("Memorandum of Association is required.")
        } else if (!formData.gst) {
            setErrorMessage("GST is required.")
        } else if (!formData.companypancard) {
            setErrorMessage("Company PAN Card is required.")
        } else if (!formData.companytancard) {
            setErrorMessage("Company TAN Card is required.")
        } else if (!formData.cancelledcheque) {
            setErrorMessage("Cancelled Cheque is required.")
        } else if (!formData.legalopinionletter) {
            setErrorMessage("Legal Opinion Letter is required.")
        } else if (!formData.license) {
            setErrorMessage("License is required.")
        } else if (!formData.addressproof) {
            setErrorMessage("Address Proof is required.")
        } else if (!formData.bankstatement) {
            setErrorMessage("Bank Statement is required.")
        } else if (!formData.merchantregstration) {
            setErrorMessage("Merchant Regstration is required.")
        } else if (!formData.merchantcriminalundertaking) {
            setErrorMessage("Merchant Criminal Undertaking is required.")
        } else if (!formData.androidapplicationdetails) {
            setErrorMessage("Android Application Details is required.")
        } else if (!formData.developerdetails) {
            setErrorMessage("Developer Details is required.")
        } else if (!formData.userloginURLdetails) {
            setErrorMessage("User Login URL Details is required.")
        } else {
            setErrorMessage('Done')
        }
        setLoading(true)
        if (!errorMessage) {
            console.log("FormData", formData);
            let url = process.env.REACT_APP_BASEURL + "/api/v1/challan";
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    "x-api-key": process.env.REACT_APP_X_API_KEY,
                },
            };
            let body = {
                emailcompany: formData.emailcompany,
                emailindividual: formData.emailindividual,
                companypannumber: formData.companypannumber,
                companyname: formData.companyname,
                companytype: formData.companytype,
                numberofDirectors: formData.numberofDirectors,
                lineOfbusiness: formData.lineOfbusiness,
                ownerfullname: formData.ownerfullname,
                registeredaddress: formData.registeredaddress,
                registeredcity: formData.registeredcity,
                registeredpin: formData.registeredpin,
                correspondenceaddress: formData.correspondenceaddress,
                correspondencecity: formData.correspondencecity,
                correspondencepin: formData.correspondencepin,
                bankname: formData.bankname,
                nameona_c: formData.nameona_c,
                a_ctype: formData.a_ctype,
                mobileno: formData.mobileno,
                accountno: formData.accountno,
                ifsccode: formData.ifsccode,
                coi: formData.coi,
                aoa: formData.aoa,
                moa: formData.moa,
                gst: formData.gst,
                companypancard: formData.companypancard,
                companytancard: formData.companytancard,
                cancelledcheque: formData.cancelledcheque,
                legalopinionletter: formData.legalopinionletter,
                license: formData.license,
                addressproof: formData.addressproof,
                bankstatement: formData.bankstatement,
                merchantregstration: formData.merchantregstration,
                merchantcriminalundertaking: formData.merchantcriminalundertaking,
                androidapplicationdetails: formData.androidapplicationdetails,
                developerdetails: formData.developerdetails,
                userloginURLdetails: formData.userloginURLdetails,
            }
            axios.post(url, body, config).then((res) => {
                setLoading(false);
                console.log("Result" + res.data)
            }).catch((err) => {
                setLoading(false)
                console.log(err);
            })
        } else {
            setLoading(false)
        }
    }
    return (
        <>
            <Row>
                <Col className="header-color" style={{ padding: '20px 20px 100px 20px', }}>
                    <h3 className="text-white" style={{ fontWeight: "bold" }}><BsDownload /> Step Form</h3>
                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                        <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '15px' }}>
                            <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                                <div style={{ marginLeft: '20px', fontWeight: "bold" }}>Step Form</div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit} >
                                {errorMessage &&
                                    <Col className="mt-1 " >
                                        <div className="alert alert-danger d-flex justify-content-between" role="alert">
                                            <div>{errorMessage}</div>
                                            <div>
                                                <Button onClick={() => setErrorMessage('')} type="submit" className="close left " style={{ background: 'transparent', border: 'none' }} aria-label="Close">
                                                    <span aria-hidden="true" style={{ fontSize: '18px', fontWeight: 'bold', color: 'red' }}>&times;</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                }
                                <Row className="mt-3">
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Email (Company)</Label>
                                            <Input name="emailcompany"
                                                onChange={(e) => { setFormData({ ...formData, emailcompany: e.target.value }) }}
                                                type="email" />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>Email (Individual)</Label>
                                            <Input type="text" name="emailindividual"
                                                onChange={(e) => { setFormData({ ...formData, emailindividual: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Company PAN Number</Label>
                                            <Input name="companypannumber" type="text"
                                                onChange={(e) => { setFormData({ ...formData, companypannumber: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Company Name</Label>
                                            <Input name="companyname" type="text"
                                                onChange={(e) => { setFormData({ ...formData, companyname: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>
                                                Company Type
                                            </Label>
                                            <Input
                                                name="companytype"
                                                type="select"
                                                onChange={(e) => { setFormData({ ...formData, companytype: e.target.value }) }}
                                            >
                                                <option selected hidden>
                                                    Select Field
                                                </option>
                                                <option>
                                                    Public Limited
                                                </option>
                                                <option>
                                                    Private Limited
                                                </option>
                                                <option>
                                                    OPC
                                                </option>
                                                <option>
                                                    LLP
                                                </option>
                                                <option>
                                                    Proprietorship
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>
                                                Number of Directors
                                            </Label>
                                            <Input
                                                name="numberofDirectors"
                                                type="select"
                                                onChange={(e) => { setFormData({ ...formData, numberofDirectors: e.target.value }) }}
                                            >
                                                <option selected hidden>
                                                    Select Field
                                                </option>
                                                <option>
                                                    1
                                                </option>
                                                <option>
                                                    2
                                                </option>
                                                <option>
                                                    3
                                                </option>
                                                <option>
                                                    4
                                                </option>
                                                <option>
                                                    5
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Line Of Business</Label>
                                            <Input name="lineOfbusiness" type="text"
                                                onChange={(e) => { setFormData({ ...formData, lineOfbusiness: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Owner’s Full Name (First Name & Last Name)</Label>
                                            <Input name="ownerfullname" type="text"
                                                onChange={(e) => { setFormData({ ...formData, ownerfullname: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>Registered address</Label>
                                            <Input name="registeredaddress" type="text"
                                                onChange={(e) => { setFormData({ ...formData, registeredaddress: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>City</Label>
                                            <Input type="text" name="registeredcity"
                                                onChange={(e) => { setFormData({ ...formData, registeredcity: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>Pin</Label>
                                            <Input type="text" name="registeredpin"
                                                onChange={(e) => { setFormData({ ...formData, registeredpin: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>Correspondence address</Label>
                                            <Input type="text" name="correspondenceaddress"
                                                onChange={(e) => { setFormData({ ...formData, correspondenceaddress: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>City</Label>
                                            <Input type="text" name="correspondencecity"
                                                onChange={(e) => { setFormData({ ...formData, correspondencecity: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>Pin</Label>
                                            <Input type="text" name="correspondencepin"
                                                onChange={(e) => { setFormData({ ...formData, correspondencepin: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>Bank Name</Label>
                                            <Input type="text" name="bankname"
                                                onChange={(e) => { setFormData({ ...formData, bankname: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>Name on A/c</Label>
                                            <Input type="text" name="nameona_c"
                                                onChange={(e) => { setFormData({ ...formData, nameona_c: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>
                                                A/c Type
                                            </Label>
                                            <Input
                                                name="a_ctype"
                                                type="select"
                                                onChange={(e) => { setFormData({ ...formData, a_ctype: e.target.value }) }}
                                            >
                                                <option selected hidden>
                                                    Select Field
                                                </option>
                                                <option>
                                                    Current
                                                </option>
                                                <option>
                                                    Saving
                                                </option>
                                                <option>
                                                    Escrow
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>Mobile Number</Label>
                                            <Input name="mobileno" type="text"
                                                onChange={(e) => { setFormData({ ...formData, mobileno: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>Account no</Label>
                                            <Input type="text" name="accountno"
                                                onChange={(e) => { setFormData({ ...formData, accountno: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>IFSC Code</Label>
                                            <Input type="text" name="ifsccode"
                                                onChange={(e) => { setFormData({ ...formData, ifsccode: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label >
                                                COI (certificate of incorporation)
                                            </Label>
                                            <Input
                                                name="coi"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, coi: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>
                                                AOA (Article of association)
                                            </Label>
                                            <Input
                                                name="aoa"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, aoa: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>
                                                MOA (Memorandum of Association)
                                            </Label>
                                            <Input
                                                name="moa"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, moa: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>
                                                GST
                                            </Label>
                                            <Input
                                                name="gst"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, gst: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>
                                                Company PAN Card
                                            </Label>
                                            <Input
                                                name="companypancard"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, companypancard: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>
                                                Company TAN Card
                                            </Label>
                                            <Input
                                                name="companytancard"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, companytancard: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>
                                                Cancelled Cheque
                                            </Label>
                                            <Input
                                                name="cancelledcheque"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, cancelledcheque: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>
                                                Legal Opinion Letter
                                            </Label>
                                            <Input
                                                name="legalopinionletter"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, legalopinionletter: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label>
                                                License
                                            </Label>
                                            <Input
                                                name="license"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, license: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>
                                                Company Recent Address Proof<br />(Electricity/Internet/Telephone Bill)
                                            </Label>
                                            <Input
                                                name="addressproof"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, addressproof: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>
                                                3 Months Bank statement(Without Pass)<br />(Settlement is to mandatorily happen in this account)
                                            </Label>
                                            <Input
                                                name="bankstatement"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, bankstatement: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>
                                                Merchant Regstration Form <br />https://docs.google.com/document/
                                                d/1M1pKvDuquNpA43aTxQBSlpf-5laJIeED/edit?usp=sharing&ouid=
                                                101192527731414671876&rtpof
                                                =true&sd=true
                                            </Label>
                                            <Input
                                                name="merchantregstration"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, merchantregstration: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>
                                                Merchant Criminal Undertaking<br />https://docs.google.com/document/
                                                d/1Z8zkRdI3Is37j0c1cox8G1mv
                                                9andAPHa/edit?usp=sharing&ouid=
                                                101192527731414671876&rtpof
                                                =true&sd=true
                                            </Label>
                                            <Input
                                                name="merchantcriminalundertaking"
                                                type="file"
                                                onChange={(e) => { setFormData({ ...formData, merchantcriminalundertaking: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>User Android Application details (Android  App)</Label>
                                            <Input type="text" name="androidapplicationdetails"
                                                onChange={(e) => { setFormData({ ...formData, androidapplicationdetails: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Developer Details: Name, Mobile Number, Place of Residence</Label>
                                            <Input type="text" name="developerdetails"
                                                onChange={(e) => { setFormData({ ...formData, developerdetails: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="12">
                                        <FormGroup>
                                            <Label>User Login URL details (Website details)
                                                (The website must show the company name and the services you offer as well as the payment flow)</Label>
                                            <Input type="text" name="userloginURLdetails"
                                                onChange={(e) => { setFormData({ ...formData, userloginURLdetails: e.target.value }) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="button-group">
                                    {loading ?
                                        <Button type="submit" className="btn header-color" style={{ borderColor: '#0149AD' }} disabled>Submit...
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                        </Button> :

                                        <Button type="submit" className="btn header-color" style={{ borderColor: '#0149AD' }}>Submit</Button>
                                    }
                                    <Button onClick={() => { navigate("/directorsDetails", {state: {count: 2}})}} className="btn header-color" style={{ borderColor: '#0149AD' }}>Next</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ChallanCreate;
