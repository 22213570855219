const AccountListData = () => {


return [
   
    
     
      {
        email: "kp.singh@gmail.com",
        name: "krishnapal singh",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
      {
        email: "kp.singh@gmail.com",
        name: "krishnapal singh",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
      {
        email: "kp.singh@gmail.com",
        name: "krishnapal singh",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
      {
        email: "kp.singh@gmail.com",
        name: "krishnapal singh",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
      {
        email: "praveen@gmail.com",
        name: "praveen Singh",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
      {
        email: "ritesh@gmail.com",
        name: "ritesh sharma",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },

          {
        email: "kp.singh@gmail.com",
        name: "krishnapal singh",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
      {
        email: "praveen@gmail.com",
        name: "praveen Singh",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
      {
        email: "ritesh@gmail.com",
        name: "ritesh sharma",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
      {
        email: "kp.singh@gmail.com",
        name: "krishnapal singh",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
      {
        email: "kp.singh@gmail.com",
        name: "krishnapal singh",
        purpose: "Recieve and transfer funds for cars",
        startDate: "2013-03-06T00:00:00Z",
        validity: 90,
        expectedVolume: 500000,
        accountId: "PPAY0000000000",
        bank: "yes_bank",
        hardEscrowAccountNumber: 428188106780,
        balance: 500000,
        ifsc: "ICIC0000104",
        status: "Active",
        authorizers: [
          {
            _id: "323232423",
            deleted: false,
            name: "Alpha",
            email: "alpha@gmail.com",
            mobile: "8899889988",
            level: 1,
            approvalsRequired: 1,
            createdAt: "2022-08-26T09:31:44.295Z",
            updatedAt: "2022-08-26T09:31:44.295Z",
            authorizerId: "AU16615063042950",
            status: "ACTIVE",
          },
        ],
        retentionAmount: 100,
        mobile: 9988998899,
        merchantId: "MR0464421368",
        createdAt: "2020-12-14T05:37:51.465Z",
        createdBy: "jane.doe@gmail.com",
      },
]
}
export { AccountListData }