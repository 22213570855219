import React from "react";

function Footer(){
return(
    <>
        <div className="d-flex justify-content-between align-items-end mt-5 " style={{ marginBottom: '20px'}}>
        <div style={{ marginLeft: '20px' }}>
            Copyright © BlinkPay 2023
        </div>
        <div style={{ marginRight: '20px' }}>
            Privacy Policy · Terms & Conditions
        </div>
    </div>
    </>
)
}
export default Footer;