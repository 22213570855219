import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaWallet } from "react-icons/fa";
import { Col, Row } from "reactstrap";
import Card from 'react-bootstrap/Card';
import UpiPayment from "./UpiPayment";
import QRCode from "./QrCode";
// import PasswordReset from "./PasswordReset";
// import Notification from "./Notification";
// import FactorAuthentication from "./FactorAuth";
const Payment = () => {
    return (
        <>
            <Row>
                <Col className="header-color" style={{ padding: '20px 20px 100px 20px', }}>
                    <h3 className="text-white" style={{ fontWeight: "500" }}><FaWallet /> Payment</h3>
                </Col>
            </Row>
            <Row style={{ marginTop: '-50px' }} >
                <Col lg={12}>
                    <Card >
                        <Card.Body style={{ padding: '0px' }}>
                            <Tabs defaultActiveKey="password" id="uncontrolled-tab-example" className="subheader-color" style={{ marginTop: '-15px', }}>
                                <Tab eventKey="password" title="UPI Payment">
                                <UpiPayment/>
                                </Tab>
                                <Tab eventKey="authentication" title="QR Code">
                                <QRCode/>
                                </Tab>                               
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </>
    );
}

export default Payment;