
import React, { useState,useEffect,useMemo} from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { CiMobile4 } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import "./Admin.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate,Link } from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
// import { AccountListData } from "./AccountListData";
// import CustomPagination from "../Pagination/CustomPagination";
const Retailer = () => {
  const [retailerList, setRetailerList] = useState([]);
  let [loading, setLoading] = useState(false);

  let [addData, setAddData] = useState({
    // public_id: 0,
  
    email: '', emailerr: '',
  
  });
  let navigate=useNavigate()
 
  // useEffect(() => {
  //   DistList()
  // }, []);

  // const DistList = () => {

  //   let url = process.env.REACT_APP_BASEURL + "/v1/asset/retailer/"
  //   const config = {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Content-type": "application/json",
  //       "Authorization": localStorage.getItem('escrowToken')
  //     }
  //   };

  //   axios.get(url, config)
  //     .then(res => {
  //       console.log("fghgfj", res.data)
  //       setRetailerList(res.data.data);
  //     }
  //     ).catch(err => {

  //     })
  // }
  const retailer = (event) => {
    event.preventDefault();
    setLoading(true)
    let error = { status: 0, email: '' };
    // if (addData.name == '') { error.name = 'Please enter name.'; error.status = 1 } else { error.name = '' }
    // if (addData.phone_number <= 0 || addData.phone_number == '') { error.phone_number = 'Please enter phone_number.'; error.status = 1 } else { error.phone_number = '' }
    // if (addData.select_dist <= 0 || addData.select_dist == '') { error.select_dist = 'Please Select Dist'; error.status = 1 } else { error.select_dist = '' }

    if (addData.email <= 0 || addData.email == '') { error.email = 'Please enter email.'; error.status = 1 } else { error.email = '' }
    setAddData({ ...addData, nameerr: error.name, phone_numbererr: error.phone_number, emailerr: error.email, select_disterr: error.select_dist, });
    if (error.status == 1) {
      setLoading(false);
    }
    if (error.status == 0) {
      // let config = {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-type": "application/json",
      //     "Authorization": localStorage.getItem('escrowToken')

      //   },
      // };

      // let url = process.env.REACT_APP_BASEURL + '/v1/asset/retailer/'
      const formData = new FormData();

      // if (!(sendImage === false)) {
      //   formData.append("profile", sendImage);
      // }
      // formData.append("name", addData.name);
      formData.append("email", addData.email);
      // formData.append("phone_number", addData.phone_number);
      // formData.append("role", 'retailer');
      // formData.append("dist_id", addData.select_dist);

      // axios.post(url, formData, config).then((res) => {
      //   setLoading(false);
      //   setAddData({ ...addData, })

      //   toast.success("Added successfully", {
      //     autoClose: 3000,
      //     theme: "colored",
      //   });

      // }).catch((err) => {
      //   setLoading(false);
      //   toast.error(err.response.data.message, {
      //     autoClose: 3000,
      //     theme: "colored",
      //   });
      // });
    }
  };

  return (
    <>
      <Row>
        <Col className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            {" "}
            <CiMobile4 /> Add Members
          </h3>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card >
            <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD' }}>
                <div style={{  fontWeight: 'bold' }}> Add Members</div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-3 ">
                <Col xs="6">
                  {/* <Input
                   name="search"
                    type="text"
                    placeholder="Search by Name"
                  /> */}
                   <Form onSubmit={retailer}  > 
                   <label>Email</label>
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="mt-2"
                      defaultValue={addData.email}
                      onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'Email  is required.' }) }}
                    />
                    <small className="text-danger">{addData.emailerr}</small>
                    <Row className="mt-4">
                  <Col>
                    <Button className="header-color" style={{ border: "none" }}>
                      Submit
                    </Button>

                  </Col>
                </Row>
                 </Form>
                </Col>
                <Col xs="6" className="d-flex justify-content-end">
                {/* <Link to="/addretailer"><Button className="header-color" style={{border:'none'}}>+ Add </Button></Link>   */}
               
                </Col>
              </Row>

              {/* <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                   
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {retailerList.map((retailer, index) => {
                    return (<tr>
                      <td>{retailer.name}</td>
                      <td>{retailer.username}</td>
                      <td>{retailer.phone_number}</td>
                    
                      <td>
                      <FaEdit className="ms-2 " style={{color:'rgb(39 104 182'}} onClick={()=>navigate(`/updateretailer/${retailer.eid}`)}></FaEdit>
                
                      </td>
                    </tr>)
                  })}
                </tbody>

              </Table> */}
              
              
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Retailer;

