
import { Navigate } from "react-router-dom";
import Login from "../views/login/Login.js";
import Dashboard from '../views/ui/Dashboard'
import ChallanList from "../views/ui/challan/ChallanList.js";
import PayeesList from "../views/ui/managepayee/PayeesList.js";
import ChallanCreate from "../views/ui/challan/ChallanCreate.js";
import BulkTransfer from "../views/ui/transfer/BulkTransfer.js";
import AccountTabs from "../views/ui/Account/AccountTabs.js";
import AccountList from "../views/ui/Account/AccountList.js";
import SingleTransfer from "../views/ui/transfer/SingleTransfer.js";
import ProfileTabs from "../views/ui/myprofile/ProfileTabs.js";
import PrivateRoutes from "../utils/PrivateRoutes.js";
import PayeeTabs from "../views/ui/managepayee/PayeesTabs.js";
import EscrowList from "../views/ui/escrow/EscrowList.js";
import CreateEscrow from "../views/ui/escrow/CreateEscrow.js";
import FundDeposit from "../views/ui/Passbook/FundDeposit.js";
import FundTransferTabs from "../views/ui/Passbook/FundTransferTabs.js";
import ManageTeams from "../views/ui/ManageTeams/ManageTeams.js";
import BussinessTabs from "../views/ui/businessprofile/BusinessTabs.js";
import Admin from "../views/ui/User/Admin.js";
import SuperDist from "../views/ui/User/Superdist.js";
import Dist from "../views/ui/User/Dist.js";
import Retailer from "../views/ui/User/Retailer.js";
import AddAdmin from "../views/ui/User/AddAdmin.js";
import AddSuperDist from "../views/ui/User/AddSuperdist.js";
import AddDist from "../views/ui/User/AddDist.js";
import AddRetailer from "../views/ui/User/AddRetailer.js";
import InteractiveDashboard from "../views/ui/interactive-dashboard/InteractiveDashboard.js";
import UpdateAdmin from "../views/ui/User/UpdateAdmin.js";
import Payment from "../views/upi_payment/Payment.js";
import UpdateSuperdist from "../views/ui/User/UpdateSuperdist.js";
import UpdateDist from "../views/ui/User/UpdateDist.js";
import UpdateRetailer from "../views/ui/User/UpdateRetailer.js";
import TransactionHistory from "../views/upi_payment/TransactionHistory.js";
import AddMember from "../admin/member/AddMember.js";
import PayOutPackageSlabs from "../views/ui/transfer/PayOutPackageSlabs.js";
import DirectorsDetails from "../views/ui/challan/DirectorsDetails.js";
import UserRagister from "../views/login/UserRagister.js";
import UserDirector from "../views/login/UserDirector.js";

const ThemeRoutes = [
  { path: "/userOnbording", exact: true, element: <UserRagister /> },
  { path: "/userdirector", exact: true, element: <UserDirector /> },

  {
    path: "/",
    element: <PrivateRoutes />,
    children: [
      { path: "/login", exact: true, element: <Login></Login> },
      { path: "/", exact: true, element: <Login></Login> },

    ],
  },
  {
    path: "/",
    element: <PrivateRoutes />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/challanlist", exact: true, element: <ChallanList /> },
      { path: "/payeeslist", exact: true, element: <PayeesList /> },
      { path: "/challancreate", exact: true, element: <ChallanCreate /> },
      { path: "/directorsDetails", exact: true, element: <DirectorsDetails /> },
      { path: "/accountlist", exact: true, element: <AccountList /> },
      { path: "/accounttabs", exact: true, element: <AccountTabs /> },
      { path: "/bulktransfer", exact: true, element: <BulkTransfer /> },
      { path: "/singletransfer", exact: true, element: <SingleTransfer /> },
      { path: "/payoutpackageslabs", exact: true, element: <PayOutPackageSlabs /> },
      { path: "/profile", exact: true, element: <ProfileTabs /> },
      { path: "/payees", exact: true, element: <PayeeTabs /> },
      { path: '/escrowlist', exact: true, element: <EscrowList /> },
      { path: '/createescrow', exact: true, element: <CreateEscrow /> },
      { path: "/funddeposit", exact: true, element: <FundDeposit /> },
      { path: "/fundtransfer", exact: true, element: <FundTransferTabs /> },
      { path: "/manageteams", exact: true, element: <ManageTeams /> },
      { path: "/bussinessprofile", exact: true, element: <BussinessTabs /> },
      { path: "/admin", exact: true, element: <Admin /> },
      { path: "/superdist", exact: true, element: <SuperDist /> },
      { path: "/dist", exact: true, element: <Dist /> },
      { path: "/retailer", exact: true, element: <Retailer /> },
      { path: "/addadmin", exact: true, element: <AddAdmin /> },
      { path: "/addsuperdist", exact: true, element: <AddSuperDist /> },
      { path: "/adddist", exact: true, element: <AddDist /> },
      { path: "/addretailer", exact: true, element: <AddRetailer /> },
      { path: "/interactiveDashboard", exact: true, element: <InteractiveDashboard /> },
      { path: "/updateadmin/:id", exact: true, element: <UpdateAdmin/> },
      { path: "/updateadmin", exact: true, element: <UpdateAdmin /> },
      { path: "/updatesuperdist/:id", exact: true, element: <UpdateSuperdist/> },
      { path: "/updatesuperdist", exact: true, element: <UpdateSuperdist/> },
      { path: "/updatedist/:id", exact: true, element: <UpdateDist/> },
      { path: "/updatedist", exact: true, element: <UpdateDist/> },
      { path: "/updateretailer/:id", exact: true, element: <UpdateRetailer/> },
      { path: "/updateretailer", exact: true, element: <UpdateRetailer/> },
      { path: "/payment", exact: true, element: <Payment /> },
      { path: "/transactionhistory", exact: true, element: <TransactionHistory/> },
      {path: "/member", exact:true,element:<AddMember/>}
    ],
  },
];
export default ThemeRoutes;

