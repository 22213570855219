import React, { useState, useEffect, useMemo } from "react";
import QRCodePackage from "qrcode.react";

import { Col, Row, Button, Table } from "reactstrap";
import { CiMobile4 } from "react-icons/ci";
import { FaWallet } from "react-icons/fa";
import { TiDownload } from "react-icons/ti";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { Input, Label, FormGroup } from "reactstrap";
// import "./Admin.css";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

// import { AccountListData } from "./AccountListData";
// import CustomPagination from "../Pagination/CustomPagination";
const QRCode = () => {
  let navigate = useNavigate();
  let PageSize = 10;

  useEffect(() => {
    getAccountList("");
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [qrString, setQrString] = useState("");

  const [accountList, setAccountList] = useState();
  const [checkVerify, setCheckVerify] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [verify, setVerify] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [vpaverify, setVpaverify] = useState(null);
  let [addData, setAddData] = useState({
    amount: null,
    remark: "",
    reciept: "",
  });
  const [amounterr, setAmounterr] = useState(null);
  const [remarkerr, setREmarkerr] = useState(null);
  const [reciepterr, setreciepterr] = useState(null);
  const handleClose = () => {
    setShow(false);
    setQrString("");
  };
  const getAccountList = (searchData) => {
    if (searchData) {
      var searchparam = "?page=" + currentPage + "&_project=" + searchData;
    } else {
      searchparam = "?page=" + currentPage;
    }
    let url = process.env.REACT_APP_BASEURL + "/api/v1/account" + searchparam;
    const getlocalStorage = localStorage.getItem(
      process.env.REACT_APP_LOCALSTORAGE_KEY
    );
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // "Accept" : "plain/text",
        Authorization: "Bearer " + getlocalStorage.token,
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        if (res) {
          console.log("RESSSSSSS", JSON.stringify(res));
          // localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
        }
      })
      .catch((error) => {
        // const getErrRes = errorHandler(error);
        // if (getErrRes === 401) {
        //   toast.error("Something went wrong.", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 2000,
        //     theme: "colored",
        //   });
        //   navigate("/");
        // } else {
        //   toast.error(getErrRes ? getErrRes : "Something went wrong.", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 2000,
        //     theme: "colored",
        //   });
        // }
      });
  };

  useMemo(() => {
    getAccountList(searchValue);
    // const firstPageIndex = (currentPage - 1) * PageSize;
    // const lastPageIndex = firstPageIndex + PageSize;
    // // console.log("firstPageIndex",firstPageIndex);
    // // console.log("PageSize",PageSize);
    // // console.log("lastPageIndex",lastPageIndex);
    // return accountList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const handlePageClick = () => {
    console.log("DATA>>>> CALL >>> handlePageClick");
  };

  const onSearch = () => {
    if (searchValue) {
      getAccountList(searchValue);
    }
  };

  const Generate = (e) => {
    e.preventDefault();
    let error = 0;

    if (addData.amount === "") {
      setAmounterr("amount is required");
      error = 1;
    }
    if (addData.remark === "") {
      setREmarkerr("remark is required");
      error = 1;
    }
    if (addData.reciept === "") {
      setreciepterr("reciept is required");
      error = 1;
    }
    if (error === 0) {
      setLoading1(true);
      let Vpa = {
        amount: addData.amount,
        remark: addData.remark,
        reciept: addData.reciept,
      };
      console.log(Vpa);

      let config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          Authorization: localStorage.getItem("escrowToken"),
        },
      };

      let url = process.env.REACT_APP_BASEURL + `v1/GenerateQR/`;
      console.log(url);
      axios
        .post(url, Vpa, config)
        .then((res) => {
          setLoading1(false);
          setTransaction(true);

          if (res?.data) {
            console.log("res.data.qrString", res.data.qrString);
            setQrString(res.data.qrString);
          }
          // navigate("/transactionhistory")
          // toast.success("QRGenerated successfully", {
          //   autoClose: 3000,
          //   theme: "colored",
          // });

          setShow(true);
        })
        .catch((err) => {
          setLoading1(false);
          toast.error(err.response.data.message, {
            autoClose: 3000,
            theme: "colored",
          });
        });
    }
  };
  let changeAmount = (e) => {
    setAddData({ ...addData, amount: e.target.value });
    if (e.target.value.trim() === "") {
      setAmounterr("Amount is required");
    } else {
      setAmounterr("");
    }
  };
  let changeRemark = (e) => {
    setAddData({ ...addData, remark: e.target.value });
    if (e.target.value.trim() === "") {
      setREmarkerr("Remark is required");
    } else {
      setREmarkerr("");
    }
  };
  let changereciept = (e) => {
    setAddData({ ...addData, reciept: e.target.value });
    if (e.target.value.trim() === "") {
      setreciepterr("reciept is required");
    } else {
      setreciepterr("");
    }
  };

  return (
    <>
      <Row style={{ padding: "15px" }}>
        <Col lg={12}>
          <>
            {" "}
            <Form onSubmit={Generate}>
              <Row>
                <Col className="mt-4">
                  <FormGroup>
                    <Label htmlFor="pdetails1">Amount</Label>
                    <Input
                      id="pdetails1"
                      type="number"
                      className=""
                      onChange={changeAmount}
                      value={addData.amount}
                    />
                  </FormGroup>
                  <small className="text-danger mb-3">{amounterr}</small>
                </Col>
                <Col className="mt-4">
                  <FormGroup>
                    <Label htmlFor="pdetails1">Remark</Label>
                    <Input
                      id="pdetails1"
                      type="text"
                      onChange={changeRemark}
                      value={addData.remark}
                    />
                  </FormGroup>
                  <small className="text-danger mb-3">{remarkerr}</small>
                </Col>
                <Col className="mt-4">
                  <FormGroup>
                    <Label htmlFor="pdetails1">Reciept</Label>
                    <Input
                      id="pdetails1"
                      type="text"
                      onChange={changereciept}
                      value={addData.reciept}
                    />
                  </FormGroup>
                  <small className="text-danger mb-3">{reciepterr}</small>
                </Col>
                <Col>
                  {loading1 ? (
                    <Button
                      type="submit"
                      className="p-2"
                      style={{
                        background: "#23528b",
                        border: "none",
                        marginTop: "53px",
                      }}
                      disabled
                    >
                      Genrating...
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className="p-2 "
                      style={{
                        background: "#23528b",
                        border: "none",
                        marginTop: "53px",
                      }}
                    >
                      GenerateQR
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>QRCode</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <QRCodePackage
                  value={qrString}
                  size="250"
                  fgColor="rgb(35, 82, 139)"
                  level="H"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: "#23528b", border: "none" }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* {transaction ? <> <Row className="mt-5">
                        <Col><h4 className="fw-bold">Transaction History</h4></Col>
                    </Row>
                        <Table
                            className="no-wrap mt-3 align-middle border-top"
                            responsive
                            borderless
                        >
                            <thead>
                                <tr>
                                    <th>Transaction Date</th>
                                    <th>Account Name</th>
                                    <th>Account Number</th>
                                    <th>Transaction ID</th>
                                    <th>Amount</th>
                                    <th> Status</th>
                                    <th>Download </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>10/01/2023</td>
                                    <td>Shivay Mehra</td>
                                    <td>85******888</td>
                                    <td>15PSA465</td>
                                    <td>2000/-</td>
                                    <td>Done</td>
                                    <td>

                                    <TiDownload className="ms-3 text-primary fs-3" ></TiDownload>
                                </td>
                                </tr>
                            </tbody>

                        </Table></>
                        : null} */}
          {/* {accountList?.length > 10 &&
                             <CustomPagination
                             className="pagination-bar"
                             currentPage={currentPage}
                             totalCount={accountList.length}
                             pageSize={PageSize}
                             onPageChange={page => setCurrentPage(page)}
                           />
                    } */}
        </Col>
      </Row>
    </>
  );
};

export default QRCode;
