import React, { useState ,useEffect, useMemo  } from "react";
import { Col, Row, Button, Input, FormGroup, Label ,Table } from "reactstrap";
import Card from "react-bootstrap/Card";
import { FiSend } from "react-icons/fi";
import Form from "react-bootstrap/Form";
import Excel from "../../../assets/images/logos/excle_log.png";
import "./transfer.css";
import { FaFileUpload } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { MdDelete } from "react-icons/md"
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";
import { AccountListData } from "../Account/AccountListData";
import CustomPagination from "../Pagination/CustomPagination";
// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';

function BulkTransfer() {
  let [openModal, setopenModal] = useState(false);
  const [fileList, setFileList] = useState([]);

  const delectFile = (id) => {
    const getIndex = fileList.map(el => el.id).indexOf(id)
    const data = [...fileList]
    if (getIndex !== -1) {
      data.splice(getIndex, 1)
      setFileList(data);
    }
  }
  const handleFileChange = (e) => {
    e.preventDefault()
    if (e.target.files) {

      const createList = [...fileList, { id: fileList.length, file: e.target.files[0], name: e.target.files[0].name }]
      console.log("createList", createList);
      setFileList(createList);
    }
  };
  let [fileName, setFileName] = useState('')
  // ===========================================================================
  const [packagepopup, setPackagepopup] = useState(false)
  
  let navigate = useNavigate();
  let PageSize = 10;

  useEffect(() => {
      getAccountList('');
  }, []);


  const [accountList, setAccountList] = useState(AccountListData());

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('')



  const getAccountList = (searchData) => {

      if (searchData) {
          var searchparam = '?page=' + currentPage + '&_project=' + searchData
      } else {
          searchparam = '?page=' + currentPage
      }
      let url = process.env.REACT_APP_BASEURL + "/api/v1/account" + searchparam;
      const getlocalStorage = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
      const config = {
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              // "Accept" : "plain/text",
              Authorization: "Bearer " + getlocalStorage.token,
              "x-api-key": process.env.REACT_APP_X_API_KEY,
          },
      };
      axios
          .get(url, config)
          .then((res) => {
              if (res) {
                  console.log("RESSSSSSS", JSON.stringify(res));
                  // localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
              }
          })
          .catch((error) => {
              // const getErrRes = errorHandler(error);
              // if (getErrRes === 401) {
              //   toast.error("Something went wrong.", {
              //     position: toast.POSITION.TOP_RIGHT,
              //     autoClose: 2000,
              //     theme: "colored",
              //   });
              //   navigate("/");
              // } else {
              //   toast.error(getErrRes ? getErrRes : "Something went wrong.", {
              //     position: toast.POSITION.TOP_RIGHT,
              //     autoClose: 2000,
              //     theme: "colored",
              //   });
              // }
          });
  };

  useMemo(() => {
      getAccountList(searchValue)
      // const firstPageIndex = (currentPage - 1) * PageSize;
      // const lastPageIndex = firstPageIndex + PageSize;
      // // console.log("firstPageIndex",firstPageIndex);
      // // console.log("PageSize",PageSize);
      // // console.log("lastPageIndex",lastPageIndex);
      // return accountList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);



  const handlePageClick = () => {
      console.log("DATA>>>> CALL >>> handlePageClick");
  }

  const onSearch = () => {
      if (searchValue) {
          getAccountList(searchValue)
      }
  }

  // ==========================other=============================
  let [formData, setFormData] = useState({
      account: '',
      payee: '',
      amount: '',
      purpose: '',
  });
  let [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
      e.preventDefault();

      if (!formData.account) {
          setErrorMessage('Account Name is required.')
      } else if (!formData.payee) {
          setErrorMessage('Payee Name is required.')
      } else if (!formData.amount) {
          setErrorMessage('Amount Name is required.')
      } else {
          setErrorMessage('')
      }
      setLoading(true)
      if (!errorMessage) {
          console.log("formData", formData);
          // const getlocalStorage = JSON.parse( localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY))

          // const config = {
          //     headers: {
          //         // "Access-Control-Allow-Origin": "*",
          //         // "Content-Type": "plain/text",
          //         // "Accept" : "plain/text",
          //         'Authorization' : 'Bearer ' + getlocalStorage.result.token,
          //         "x-api-key": process.env.REACT_APP_X_API_KEY,
          //     },
          // };
          // let body =  ({
          //     name: formData.accountName,
          //     email: formData.email,
          //     purpose: formData.purpose,
          //     startDate: formData.startDate,
          //     validity: formData.validity,
          //     expectedVolume: formData.expectedVolume,
          //     bank: "icici",
          //     documentIds: [
          //     "DC9039302951"
          //     ]
          //     })

          // let url = process.env.REACT_APP_BASEURL + "/api/v1/account"         

          // axios.post(url, body, config)
          //     .then(res => {
          //         setLoading(false);
          //         console.log("res", JSON.stringify(res));

          //     }).catch(error => {
          //         console.log("ERRRR",error);
          //         setLoading(false);
          //         if (error) {
          //             const getErrRes = errorHandler(error)
          //             // const getErrRes = ''
          //             if (getErrRes === 401) {
          //                 navigate("/");
          //             } else {
          //                 toast.error(getErrRes ? getErrRes : 'Something went wrong.', {
          //                     position: toast.POSITION.TOP_RIGHT,
          //                     autoClose: 3000,
          //                     theme: "colored",
          //                 });
          //             }
          //         }
          //     })
      } else {
          setLoading(false);
      }
  };

  return (
    <>

      <Row>
        <Col className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h2 className="text-white">
            {" "}
            <FiSend /> Payin Package Slabs
          </h2>
          {/* <span className="text-white">Create a new Bulk Transfer Entry </span> */}
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card >
            <Card.Header className="subheader-color" style={{ backgroundColor: '', padding: '20px' }}>
              <div className="d-flex justify-content-between " style={{ color: '#0149AD', fontWeight: 'bold' }}>
                <div style={{ marginLeft: '20px' }}> Payin Package Slabs</div>
                {/* <div style={{ marginRight: '20px' }}><BsThreeDotsVertical /></div> */}
                <div>
                  <Button className="header-color rounded-pill"
                    style={{
                      // borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                      cursor: "pointer"
                    }}
                    onClick={() => { setPackagepopup(true) }}
                  >
                    Add New Package
                  </Button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {/* <Row className="mt-3">
                <Col>
                  <label>Select Account Number</label>
                  <Form.Select className="mt-2">
                    <option>Enter Account name</option>
                    <option disabled>No Items Found</option>
                  </Form.Select>
                </Col>
                <Col>
                  <label>Batch Name</label>
                  <Input
                    name="batch_name"
                    type="text"
                    placeholder="Batch Name"
                    className="mt-2"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Button
                    onClick={() => {
                      setopenModal(true);
                    }}
                    style={{ background: "#23528b", border: "none" }}
                  >
                    Add Supporting Documents
                  </Button>
                  {openModal && (
                    <>
                      <Modal
                      show={true}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <div className="text-center h3 mt-4 fs-bolder">Add Doc</div>
                        <Modal.Body>
                          <div className="container">

                            {
                              
                               fileList.length> 0 && fileList.map((el, index)=>{

                                return(
                                  <div key={index} className="row m-2 mb-3">
                                  <div className="col-11">
                                  <Input
                                    readOnly
                                    type="text"
                                    value={el.name}
                                    placeholder="Doc Name"
                                    className="py-2"
                                  />
                                  <p> {el.name }</p>
                                  </div>
                                  <div className="col-1 d-flex justify-contant-center">
                                    <Button onClick={()=>{delectFile(el.id)}} className="btn btn-danger"><MdDelete/></Button>
                                  </div>
                                </div>
                                )
                              })

                       
                            }

                            {fileList.length === 0 && <h5 className="justify-content-center ms-5 p-5">No Documents Uploaded</h5>}
                          </div>
                        </Modal.Body>
     
                        <div className="row m-3">
                          <div className="col text-end">
                           <label htmlFor="bulk-file-upload" className="custom-file-upload text-center p-2 rounded-2 bg-info">
                        Upload File <FaFileUpload/>
                      </label>
                           <input style={{display:'none'}} id="bulk-file-upload" type="file" onChange={handleFileChange}/>
                          </div>
                          <div className="col text-start">
                           <Button onClick={() => {setopenModal(false)}} className="py-2 px-4 btn btn-dark">Close</Button>
                          </div>
                        </div>
                      </Modal>
                    </>
                  )}
                </Col>
              </Row>
              <div className="d-flex justify-content-between mt-5">
                <div>
                  Enter The requird details in the template and upload (.XLS,
                  .XLSX, .CSV)
                </div>
                <div className="pe-5">
                  Download Template <img src={Excel} style={{ height: '28px', width: '28px' }} alt="title"></img>
                </div>
              </div>
              <Row className="mt-3 py-5 p-3">
                <label htmlFor="file-upload" className="custom-file-upload text-center "
                  style={{
                    border: "1px solid #ccc",
                    display: "inline-block",
                    padding: " 26px",
                    boxShadow: "0px 1px 16px 1px #00bcd459", cursor: " pointer"
                  }}>
                  Upload File <FaFileUpload />
                  <input id="file-upload" type="file" style={{ display: 'none' }} onChange={(e) => { setFileName(e.target.files[0].name) }} />
                  <br />
                  <span id="imageName" style={{ color: " green" }}>{fileName}</span>
                </label>
              </Row> */}
              <Row className="mt-3">
                <Col xs="6">
                  <Input
                    value={searchValue}
                    onChange={(e) => { setSearchValue(e.target.value) }} name="search"
                    type="text"
                    placeholder="Search by ID"
                  />
                </Col>
                <Col xs="6">
                  <Button
                    onClick={() => onSearch()}
                    style={{
                      backgroundColor: "#a2751a",
                      border: "none",
                      marginRight: "5px",
                    }}
                  >
                    <FaSearch />{" "}
                  </Button>
                  <span
                    onClick={() => {
                      setSearchValue('')
                      getAccountList('')
                    }
                    }
                    style={{ color: "#0149AD", marginRight: "5px" }}>
                    {" "}
                    clear
                  </span>
                </Col>
              </Row>
              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th className="text-center">ID.</th>
                    <th className="text-center">Package Name</th>
                    <th className="text-center">Package Created Date</th>
                    <th className="text-center">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {accountList?.length &&
                    accountList?.length > 0 &&
                    accountList.map((item, index) => {
                      return (
                        <tr className="border-top">
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{item.balance}</td>
                          <td className="text-center">21/07/2023</td>
                          <td className="text-center"><MdDelete className="fs-4" style={{ cursor: "pointer" }} /></td>
                        </tr>
                      );
                    })}

                  {accountList.length === 0 && <h4>NO DATA Available</h4>}
                </tbody>
              </Table>
              {accountList?.length > 10 &&
                <CustomPagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={accountList.length}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* ============================Add Package Popup============================ */}
      <Modal show={packagepopup} onHide={() => { setPackagepopup(false); }} size="md">
        <Modal.Header closeButton>Add New Package</Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Label>Package Name</Label>
            <Input
              name="select"
              type="select"
            >
              <option value="selected" hidden>Enter Package Name</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </Input>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={() => { setPackagepopup(false); }}>Add</Button>
          <Button color="secondary" onClick={() => { setPackagepopup(false); }}>Discard</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BulkTransfer;
